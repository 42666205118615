import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "../../atoms/Image/Image"
import SliderCarousel from "../../organisms/SliderCarousel/SliderCarousel"
import { css } from "@emotion/react"
import Text from "../../atoms/Text/Text"

export default function TabsFundamentaMas({
  dataEspacios,
  dataDiseno,
  isProyecto,
  isAnimado,
  titlePage,
  texto,
}) {
  const data = useStaticQuery(graphql`
    {
      wordpress {
        page(id: "596", idType: DATABASE_ID) {
          title
          template {
            ... on Wordpress_Template_FundamentaMas {
              fundamentaMas {
                textoInicioMovilEspaciosMas
                textoInicioMovilDisenoMas
                imagenInicioMovilEspaciosMas {
                  ...imagen
                }
                imagenInicioMovilDisenoMas {
                  ...imagen
                }
                galeriaDisenoMas {
                  imagen {
                    ...imagen
                  }
                  texto
                }
                galeriaEspaciosMas {
                  imagen {
                    ...imagen
                  }
                  texto
                }
                logoBlancoDisenoMas {
                  ...imagen
                }
                logoBlancoEspaciosMas {
                  ...imagen
                }
                logoVerdeDisenoMas {
                  ...imagen
                }
                logoVerdeEspaciosMas {
                  ...imagen
                }
                textoDisenoMas
                textoEspaciosMas
              }
            }
          }
        }
      }
    }
  `)

  const page = data?.wordpress?.page
  const template = page?.template?.fundamentaMas

  const galeriaEspaciosMas = dataEspacios
    ? dataEspacios
    : template?.galeriaEspaciosMas
  const galeriaDisenoMas = dataDiseno ? dataDiseno : template?.galeriaDisenoMas

  const tabCss = css`
    width: 50%;
    background-color: #5e5e5e;
    padding-top: calc(100vw * (calc(24 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(24 / var(--width_base))));
    min-height: calc(100vw * (calc(103 / var(--width_base))));
    @media (max-width: 767px) {
      ${isProyecto && "background-color: #5E5E5E"};
    }
    &.is-active {
      background-color: #444443;
      padding-top: calc(100vw * (calc(15 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(15 / var(--width_base))));
      @media (max-width: 767px) {
        ${isProyecto && "background-color: #444443"};
      }
    }
    & * {
      pointer-events: none;
    }
    &:hover {
      padding-top: calc(100vw * (calc(15 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(15 / var(--width_base))));
      @media (max-width: 767px) {
        padding-top: calc(100vw * (calc(24 / var(--width_base))));
        padding-bottom: calc(100vw * (calc(24 / var(--width_base))));
      }
    }
    &:hover .tab-image-2 img {
      width: calc(100vw * (calc(120 / var(--width_base))));
      height: auto;
      max-width: fit-content;
      @media (max-width: 767px) {
        pointer-events: none;
      }
    }
    &.is-active .tab-image-2,
    & .tab-image-1 {
      display: none !important;
    }
    &.is-active .tab-image-1 {
      display: block !important;
    }
    &.is-active .tab-image-1 img {
      width: calc(100vw * (calc(120 / var(--width_base))));
      height: auto;
      max-width: fit-content;
      @media (max-width: 767px) {
        width: calc(100vw * (calc(120 / var(--width_base))));
        height: calc(100vw * (calc(55 / var(--width_base))));
        max-width: calc(100vw * (calc(83 / var(--width_base))));
      }
    }
    &:nth-of-type(1) {
      padding-left: calc(100vw * (calc(165 / var(--width_base))));
      @media (max-width: 767px) {
        padding-left: calc(100vw * (calc(50 / var(--width_base))));
        padding-right: calc(100vw * (calc(50 / var(--width_base))));
      }
    }
    &:nth-of-type(2) {
      padding-left: calc(100vw * (calc(60 / var(--width_base))));
      @media (max-width: 767px) {
        padding-left: calc(100vw * (calc(50 / var(--width_base))));
        padding-right: calc(100vw * (calc(50 / var(--width_base))));
      }
    }
  `

  const tabList = css`
    display: flex;
  `

  const imageCss = css`
    height: calc(100vw * (calc(55 / var(--width_base))));
    max-width: calc(100vw * (calc(103 / var(--width_base))));
  `

  const tabPannel = css`
    display: none;
    padding-top: calc(100vw * (calc(39 / var(--width_base))));
    padding-left: calc(100vw * (calc(150 / var(--width_base))));
    padding-right: calc(100vw * (calc(150 / var(--width_base))));
    background-color: #444443;
    min-height: calc(100vw * (calc(288 / var(--width_base))));
    @media (max-width: 767px) {
      padding-top: calc(100vw * (calc(30 / var(--width_base))));
      padding-left: initial;
      padding-right: initial;
      ${isProyecto && "background-color: #444443"}
    }

    &.is-active {
      display: block;
    }
  `

  const contentCss = css`
    background-color: #444443;
    text-align: center;
    padding-bottom: calc(100vw * (calc(50 / var(--width_base))));
    font-family: "Montserrat Regular", sans-serif;
    @media (max-width: 767px) {
      padding-bottom: calc(100vw * (calc(30 / var(--width_base))));
      ${isProyecto && "background-color: #444443"}
    }
  `

  // const btnCss = css`
  //   margin-top: calc(100vw * (calc(27 / var(--width_base))));
  // `

  const textCss = css`
    margin-top: calc(100vw * (calc(43 / var(--width_base))));
    font-size: calc(100vw * (calc(20 / var(--width_base))));
    line-height: calc(100vw * (calc(27 / var(--width_base))));
  `
  const textTabsCss = css`
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    line-height: calc(100vw * (calc(24 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(30 / var(--width_base))));
    padding-inline: calc(100vw * (calc(65 / var(--width_base))));
    text-align: center;
  `
  const textInicioCss = css`
    position: relative;
    font-size: calc(100vw * (calc(13 / var(--width_base))));
    line-height: calc(100vw * (calc(13 / var(--width_base))));
    color: white;
    text-align: center;
    margin-top: calc(100vw * (calc(-13 / var(--width_base))));
    transform: translateY(calc(100vw * (calc(-20 / var(--width_base)))));
  `

  const sliderCss = css`
    & .slick-next {
      @media (max-width: 767px) {
        right: calc(100vw * (calc(15 / var(--width_base))));
      }
    }

    & .slick-prev {
      @media (max-width: 767px) {
        left: calc(100vw * (calc(15 / var(--width_base))));
      }
    }

    & polyline {
      @media (max-width: 767px) {
        stroke: white !important;
      }
    }
  `

  // SCRIPT
  const handleTab = e => {
    const tabsButton = document.querySelectorAll(".tabs-button")
    tabsButton.forEach(
      ele =>
        ele.classList.contains("is-active") && ele.classList.remove("is-active")
    )
    let tabsPanel = document.querySelectorAll(".tabs-panel")
    tabsPanel.forEach(
      ele =>
        ele.classList.contains("is-active") && ele.classList.remove("is-active")
    )
    e.target.classList.add("is-active")
    document.getElementById(e.target.dataset.id).classList.add("is-active")
  }

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0}
      tabIndex="-1"
      type="button"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.36 67.47">
        <polyline
          fill="none"
          stroke="#93BA1F"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="7px"
          points="35.86 3.5 4.95 34.41 34.51 63.97"
        />
      </svg>
    </button>
  )

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      tabIndex="-1"
      aria-disabled={currentSlide === slideCount - 1}
      type="button"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.36 67.47">
        <polyline
          fill="none"
          stroke="#93BA1F"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="7px"
          points="3.5 3.5 34.41 34.41 4.84 63.97"
        />
      </svg>
    </button>
  )

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    /*autoplay: true,*/
    autoplaySpeed: 6000,
    // pauseOnHover: false,
    // pauseOnFocus: false,
    // swipe: true,
    pauseOnHover: false,
    pauseOnFocus: false,
    swipe: true,
    touchMove: false,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <section css={contentCss}>
      {titlePage && (
        <Text
          classEle="d-none"
          data={`Tabs Fundamenta Más ${titlePage}`}
          typeEle="h2"
        />
      )}
      <div>
        <div css={tabList}>
          <button
            css={tabCss}
            data-id="tabs-panel-1"
            onClick={handleTab}
            className="tabs-button is-active"
            data-aos={isAnimado && "fade-right"}
            data-aos-delay="200"
          >
            <Image
              imagen={template?.logoBlancoEspaciosMas}
              styleCss={imageCss}
              objectFit="contain"
              classEle="tab-image-1"
            />
            <Image
              imagen={template?.logoVerdeEspaciosMas}
              styleCss={imageCss}
              objectFit="contain"
              classEle="tab-image-2"
            />
          </button>
          <button
            css={tabCss}
            data-id="tabs-panel-2"
            onClick={handleTab}
            className="tabs-button"
            data-aos={isAnimado && "fade-left"}
            data-aos-delay="200"
          >
            <Image
              imagen={template?.logoBlancoDisenoMas}
              styleCss={imageCss}
              objectFit="contain"
              classEle="tab-image-1"
            />
            <Image
              imagen={template?.logoVerdeDisenoMas}
              styleCss={imageCss}
              objectFit="contain"
              classEle="tab-image-2"
            />
          </button>
        </div>
        <div css={tabPannel} id="tabs-panel-1" className="tabs-panel is-active">
          <div className={!isProyecto ? "only-desk" : ""}>
            <Text
              className={"only-mov"}
              styleCss={textTabsCss}
              colorText={"#7fbc03"}
              data="ESPACIOS PENSADOS PARA TU FELICIDAD"
            />
            {galeriaEspaciosMas && (
              <SliderCarousel
                data={galeriaEspaciosMas}
                settings={settings}
                typeStyle="carousel3"
                styleCss={sliderCss}
                fancybox={true}
              />
            )}
            {texto ? (
              <Text
                data={texto}
                styleCss={textCss}
                colorText="white"
                align="center"
                classEle="only-desk"
                data-aos={isAnimado && "fade-right"}
                data-aos-delay="200"
              />
            ) : (
              <Text
                data={template?.textoEspaciosMas}
                styleCss={textCss}
                colorText="white"
                align="center"
                classEle="only-desk"
                data-aos={isAnimado && "fade-right"}
                data-aos-delay="200"
              />
            )}
          </div>
          {!isProyecto && (
            <div className="only-mov">
              {template?.imagenInicioMovilEspaciosMas && (
                <Image imagen={template?.imagenInicioMovilEspaciosMas} />
              )}
              {template?.textoInicioMovilEspaciosMas && (
                <Text
                  data={template?.textoInicioMovilEspaciosMas}
                  styleCss={textInicioCss}
                />
              )}
            </div>
          )}
        </div>
        <div css={tabPannel} className="tabs-panel" id="tabs-panel-2">
          <div className={!isProyecto ? "only-desk" : ""}>
            <Text
              className={"only-mov"}
              styleCss={textTabsCss}
              colorText={"#7fbc03"}
              data="DISEÑO INTERIOR CREADO PARA VIVIR TU FELICIDAD"
            />
            {galeriaDisenoMas && (
              <SliderCarousel
                data={galeriaDisenoMas}
                settings={settings}
                typeStyle="carousel3"
                styleCss={sliderCss}
              />
            )}
            {texto ? (
              <Text
                data={texto}
                styleCss={textCss}
                colorText="white"
                classEle="only-desk"
                align="center"
                data-aos={isAnimado && "fade-left"}
                data-aos-delay="200"
              />
            ) : (
              <Text
                data={template?.textoDisenoMas}
                styleCss={textCss}
                colorText="white"
                classEle="only-desk"
                align="center"
                data-aos={isAnimado && "fade-left"}
                data-aos-delay="200"
              />
            )}
          </div>
          {!isProyecto && (
            <div className="only-mov">
              {template?.imagenInicioMovilDisenoMas && (
                <Image imagen={template?.imagenInicioMovilDisenoMas} />
              )}
              {template?.textoInicioMovilDisenoMas && (
                <Text
                  data={template?.textoInicioMovilDisenoMas}
                  styleCss={textInicioCss}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {/* {!isProyecto && (
        <Button
          typeEle="link"
          link="/fundamenta-mas/"
          styleTypeEle="ver-mas"
          styleEle={btnCss}
          data-aos="fade-right"
          data-aos-delay="300"
        >
          Ver más
        </Button>
      )} */}
    </section>
  )
}

TabsFundamentaMas.defaultProps = {
  isProyecto: false,
  isAnimado: false,
}
